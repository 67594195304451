












































































































.framePage-body {
  .contentNotification {
    padding: 30px;
    p {
      text-indent:0 !important;
      width: 100% !important;
      margin-bottom: 15px !important;
      // span {
      //   // text-indent: 20px !important;
      // }
    }
  }
}
